import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { logEvent } from "@src/utils/logClient";
import { Button, Heading, BodyText, CheckmarkIcon, DownloadIcon, theme } from "@src/components/nessie-web";
import styled from "@emotion/styled";
import Container from "@src/components/Container";
import CommonModal from "@src/components/modals/CommonModal";
import { mediaQueriesMax } from "@src/styles/theme";
import SEO from "@src/components/SEO";

const PageBackground = styled.div`
  background-color: #addaff;
  background-image: url(https://static.classdojo.com/img/2024/01/bett-uk.svg);
  background-position: left bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding-block: 60px;
  background-size: contain;
  input[type="text"],
  input[type="email"] {
    display: block;
    margin-top: 6px;
    width: 100%;
    padding: 12px 24px;
    border-radius: 99px;
    border: 2px solid ${theme.colors.taro30};
    background-color: ${theme.colors.taro10};
  }
  input[type="checkbox"] {
    margin-top: 4px;
  }
  ${mediaQueriesMax[0]} {
    background-size: 300px;
    background-position: left top;
    padding-bottom: 0;
  }
`;

const PageContainer = styled.div`
  ${mediaQueriesMax[0]} {
    border-radius: 30px 30px 0 0;
    width: auto;
    margin-top: 350px;
    margin-left: -24px;
    margin-right: -24px;
  }
  width: 50%;
  background: #fff;
  border-radius: 30px;
  padding: 36px;
  margin-left: auto;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

const BettUK2024 = () => {
  const [pardotFormData, setPardotFormData] = useState({
    Email: "",
    FirstName: "",
    LastName: "",
    SchoolDistrict: "",
    JobTitle: "",
    Consent: false,
    confirmEmail: "",
  });

  function handleSubmit(ev: FormEvent) {
    ev.preventDefault();
    if (pardotFormData.confirmEmail) {
      ev.preventDefault();
      return;
    }
    logEvent({
      eventName: "web.external.districts.submit_usage_form",
    });
    const form = ev.target as HTMLFormElement;
    form.submit();
  }

  function handleInputChange(ev: ChangeEvent<HTMLInputElement>) {
    const value = ev.target.type === "checkbox" ? ev.target.checked : ev.target.value;
    setPardotFormData({
      ...pardotFormData,
      [ev.target.name]: value,
    });
  }
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("success") !== null) {
      setShowSuccess(true);
      logEvent({
        eventName: "web.external.bett-uk-2024.submitted-form",
        eventValue: window.location.href,
      });
    } else {
      logEvent({
        eventName: "web.external.bett-uk-2024.page-view",
        eventValue: window.location.href,
      });
    }
  }, []);

  const closeModal = () => {
    setShowSuccess(false);
  };

  return (
    <PageBackground>
      <SEO title="ClassDojo at BETT UK 2024" />
      <Container>
        <PageContainer>
          <Heading>Thanks for connecting with us at BETT UK!</Heading>
          <BodyText>
            Want to join the thousands of ClassDojo teachers in 180 countries making their classrooms feel more like
            communities? <br />
            Fill out this form to access special content designed to help students build STEM and teamwork skills.
          </BodyText>
          <form
            action="https://learn.classdojo.com/l/1046033/2024-01-16/23rc"
            method="post"
            onSubmit={handleSubmit}
            css={{ display: "flex", flexDirection: "column", gap: 16, marginTop: 24 }}
          >
            <div>
              <label htmlFor="Email">
                <BodyText>Your first name:</BodyText>
              </label>
              <input
                id="FirstName"
                name="FirstName"
                type="text"
                value={pardotFormData.FirstName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label htmlFor="LastName">
                <BodyText>Your last name:</BodyText>
              </label>
              <input
                id="LastName"
                name="LastName"
                type="text"
                value={pardotFormData.LastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label htmlFor="FirstName">
                <BodyText>Email (please provide your ClassDojo email if you have one)</BodyText>
              </label>
              <input
                id="Email"
                name="Email"
                type="email"
                value={pardotFormData.Email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <label htmlFor="SchoolDistrict">
                <BodyText>What is the name of your school or organisation?</BodyText>
              </label>
              <input
                id="SchoolDistrict"
                name="SchoolDistrict"
                type="text"
                value={pardotFormData.SchoolDistrict}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <label htmlFor="JobTitle">
                <BodyText>Your job title:</BodyText>
              </label>
              <input
                id="JobTitle"
                name="JobTitle"
                type="text"
                value={pardotFormData.JobTitle}
                onChange={handleInputChange}
                required
              />
            </div>
            <CheckboxContainer>
              <input
                id="Consent"
                name="Consent"
                type="checkbox"
                checked={pardotFormData.Consent}
                onChange={handleInputChange}
              />
              <label htmlFor="Consent">
                <BodyText>Opt me in to receive promotional emails from ClassDojo</BodyText>
              </label>
            </CheckboxContainer>
            <CheckboxContainer>
              <input id="GDPR" name="GDPR" type="checkbox" required />
              <label htmlFor="GDPR">
                <BodyText>I consent to sharing my information in line with GDPR standards</BodyText>
              </label>
            </CheckboxContainer>
            <div css={{ display: "none" }}>
              <input
                className="confirmEmailField"
                type="text"
                name="confirmEmail"
                value={pardotFormData.confirmEmail}
                placeholder="Confirm your work e-mail address"
                onChange={handleInputChange}
              />
            </div>
            <Button kind="plus" type="submit">
              Download my free kit
            </Button>
          </form>
        </PageContainer>
        {showSuccess && (
          <CommonModal closeModal={closeModal}>
            <p>Thank you! Please check your e-mail</p>
          </CommonModal>
        )}
      </Container>
    </PageBackground>
  );
};

export default BettUK2024;
